<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<section class="log-card__header">
	<ctx-log-thumbnail [log]="log" size="l"></ctx-log-thumbnail>

	<div class="log-card__header-information">
		<h2 class="margin-0">{{ log.name }}</h2>
		<small class="margin-v-1">
			{{ log.createdAt | relativeTime }}
		</small>

		@if (log.media.length) {
			<small>
				{{ 'log.media-#' | transloco: { count: log.media.length } }}
			</small>
		}
	</div>

	<div class="log-card__header-actions">
		@if (isArchived) {
			<tui-badge appearance="neutral" iconStart="@tui.archive">
				<span class="log-card__header-actions-badge-text">
					{{ 'content.archived' | transloco }}
				</span>
			</tui-badge>
		}

		@if (actionable && menuItems.length) {
			@if ('mobile' | isDevice) {
				<button
					size="m"
					tuiIconButton
					appearance="ghost"
					(click)="onMenuOpen()"
				>
					<tui-icon icon="@tui.ellipsis"></tui-icon>
				</button>
			} @else {
				<button
					size="m"
					tuiIconButton
					appearance="ghost"
					[tuiDropdown]="logDropdown"
					[(tuiDropdownOpen)]="menuOpen"
				>
					<tui-icon icon="@tui.ellipsis"></tui-icon>
				</button>
			}
		}

		<button
			[class.rotate]="!collapsed"
			tuiIconButton
			appearance="ghost"
			(click)="onToggleCollapsed()"
		>
			<tui-icon icon="@tui.chevron-down" />
		</button>
	</div>
</section>

@if (!collapsed) {
	<section class="log-card__content">
		@if (loading) {
			<tui-loader size="xl" />
		} @else {
			@if (author && access !== 'view') {
				<label>
					<p class="font-bold margin-top-0 margin-bottom-1">
						{{ 'content.owner' | transloco }}
					</p>

					<div class="log-card__content-author margin-bottom-3">
						<ctx-avatar [user]="author"></ctx-avatar>
						<p>{{ author | userName }}</p>
					</div>
				</label>
			}

			<label>
				<p class="margin-top-0 margin-bottom-1 font-bold">
					{{ 'data.created-at' | transloco }}
				</p>
				<p class="margin-top-1 margin-bottom-3">
					{{ log.createdAt.toDate() | date: 'MM/dd/YYYY hh:mm aa' }}
				</p>
			</label>

			@if (log.description) {
				<label>
					<p class="margin-top-0 margin-bottom-1 font-bold">
						{{ 'data.description' | transloco }}
					</p>
					<p class="margin-top-1 margin-bottom-3">
						{{ log.description }}
					</p>
				</label>
			}

			@if (media.length) {
				<div class="log-card__content-media" tuiCarouselButtons>
					<label>
						<p class="margin-top-0 margin-bottom-2 font-bold">
							{{ 'data.media' | transloco }}
						</p>

						<div class="log-card__content-media-carousel">
							@if (media.length > 1) {
								<button
									iconStart="@tui.chevron-left"
									tuiIconButton
									type="button"
									(click)="carousel.prev()"
									appearance="ghost"
								>
									{{ 'action.previous' | transloco }}
								</button>
							}

							<tui-carousel #carousel class="carousel">
								@for (item of media; track item.id) {
									<div
										*tuiItem
										class="log-card__content-media-item"
									>
										<div
											class="log-card__content-media-item-information"
										>
											<ctx-media-thumbnail
												[media]="item"
											></ctx-media-thumbnail>

											<p>{{ item.name }}</p>

											<button
												tuiIconButton
												iconStart="@tui.eye"
												appearance="ghost"
												(click)="onView(item)"
											>
												{{ 'action.view' | transloco }}
											</button>
										</div>
									</div>
								}
							</tui-carousel>

							@if (media.length > 1) {
								<button
									iconStart="@tui.chevron-right"
									tuiIconButton
									type="button"
									(click)="carousel.next()"
									appearance="ghost"
								>
									{{ 'action.next' | transloco }}
								</button>
							}
						</div>
					</label>
				</div>
			}
		}
	</section>
}

<ng-template #logDropdown="polymorpheus" polymorpheus>
	<ctx-detail-menu-dropdown
		[menuItems]="menuItems"
	></ctx-detail-menu-dropdown>
</ng-template>
