<section tuiContent>
	@if (loading) {
		<div class="padding-v-4">
			<tui-loader size="xl" />
		</div>
	} @else if (form) {
		<form [formGroup]="form">
			<tui-textfield
				tuiTextfieldSize="m"
				iconStart="@tui.search"
				size="s"
			>
				<input
					formControlName="search"
					[placeholder]="'action.search' | transloco"
					tuiTextfield
					tuiAutoFocus
				/>
			</tui-textfield>

			<ctx-table
				[pagination]="false"
				[loading]="loading"
				[empty]="!filteredContent.length"
				[placeholder]="
					form.get('search')?.value || searching
						? placeholder
						: placeholderContent
				"
			>
				@for (item of filteredContent; track item.id) {
					<ctx-content-row
						[content]="item.data"
						[interactable]="false"
						[showAccess]="false"
					>
						<input
							start
							tuiRadio
							type="radio"
							[value]="item.id"
							formControlName="selected"
						/>
					</ctx-content-row>
				}
			</ctx-table>
		</form>
	}
</section>

<footer tuiFooter>
	<button tuiButton appearance="outline" (click)="onDismiss()">
		{{ 'action.cancel' | transloco }}
	</button>
	<button tuiButton [loading]="busy" appearance="accent" (click)="onSubmit()">
		{{ 'action.save' | transloco }}
	</button>
</footer>
