<ng-content select="[start]" />

<ctx-content-thumbnail
	[size]="size"
	[content]="content"
></ctx-content-thumbnail>

<div class="content-row__information">
	@if (label) {
		<small class="content-row__information-label margin-0">
			{{ label }}
		</small>
	}

	<p class="margin-0">
		{{ content.name }}
	</p>

	@if (asTableRow && !label) {
		<small class="margin-0">
			{{
				'data.last-edited-at'
					| transloco: { time: content.updatedAt | relativeTime }
			}}
		</small>
	}
</div>

<div class="content-row__actions">
	@if (asTableRow) {
		@if (showAccess) {
			@if (isShared) {
				<tui-badge
					appearance="info"
					iconStart="@tui.share-2"
					tuiHintAppearance="dark"
					[tuiHint]="'content.shared-access-description' | transloco"
				>
					<span class="content-row__actions-badge-text">
						{{ 'content.shared-access' | transloco }}
					</span>
				</tui-badge>
			} @else if (!isMine && roleAccess) {
				<tui-badge
					appearance="neutral"
					iconStart="@tui.circle-user"
					tuiHintAppearance="dark"
					[tuiHint]="
						'content.role-access-description'
							| transloco
								: { role: 'user.roles.' + role | transloco }
					"
				>
					<span class="content-row__actions-badge-text">
						{{ 'content.role-access' | transloco }}
					</span>
				</tui-badge>
			}
		}

		@if (interactable) {
			<tui-icon icon="@tui.arrow-right"></tui-icon>
		}
	} @else {
		<ng-content select="[end]" />
	}
</div>
