import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	inject,
	Input,
	OnInit,
} from '@angular/core';
import { StorageService } from '@context/frontend/storage';
import { AlgoliaContent } from '@context/shared/types/search';
import {
	TuiFallbackSrcPipe
} from '@taiga-ui/core';
import { TuiAvatar } from '@taiga-ui/kit';

const IconMap: { [key in 'folder' | 'file']: string } = {
	file: '@tui.sprout',
	folder: '@tui.folder',
};

@Component({
	standalone: true,
	imports: [TuiAvatar, TuiFallbackSrcPipe, AsyncPipe],
	selector: 'ctx-algolia-content-thumbnail',
	templateUrl: 'algolia-content-thumbnail.component.html',
	styleUrl: 'algolia-content-thumbnail.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlgoliaContentThumbnailComponent implements OnInit {
	@Input()
	content!: AlgoliaContent;

	readonly storageService = inject(StorageService);
	readonly cdRef = inject(ChangeDetectorRef);

	thumbnailUrl: string | null = null;

	get icon() {
		return IconMap[this.content.type as 'file' | 'folder'];
	}

	ngOnInit() {
		this.fetchThumbnail().then((res) => {
			this.thumbnailUrl = res;
			this.cdRef.detectChanges();
		});
	}

	fetchThumbnail() {
		if (!this.content.thumbnail) return Promise.resolve(null);

		const storagePath = this.storageService.getReference(
			this.content.thumbnail,
		);
		return this.storageService.getDownloadUrl(storagePath);
	}
}
