import { TuiItem } from '@taiga-ui/cdk/directives/item';
import { __decorate } from 'tslib';
import { AsyncPipe, NgForOf, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import * as i0 from '@angular/core';
import { inject, Directive, Input, Output, ChangeDetectorRef, EventEmitter, TemplateRef, Component, ChangeDetectionStrategy, ContentChildren } from '@angular/core';
import * as i2 from '@ng-web-apis/intersection-observer';
import { WaIntersectionObserver } from '@ng-web-apis/intersection-observer';
import { TUI_FALSE_HANDLER, TUI_TRUE_HANDLER, EMPTY_QUERY } from '@taiga-ui/cdk/constants';
import { TuiPan } from '@taiga-ui/cdk/directives/pan';
import { TuiSwipe } from '@taiga-ui/cdk/directives/swipe';
import { TUI_IS_MOBILE } from '@taiga-ui/cdk/tokens';
import { tuiInjectElement } from '@taiga-ui/cdk/utils/dom';
import { tuiClamp } from '@taiga-ui/cdk/utils/math';
import { tuiPure } from '@taiga-ui/cdk/utils/miscellaneous';
import { WA_PAGE_VISIBILITY } from '@ng-web-apis/common';
import { tuiTypedFromEvent, tuiIfMap, tuiZonefreeScheduler } from '@taiga-ui/cdk/observables';
import { Observable, BehaviorSubject, merge, map, combineLatest, interval, filter, throttleTime, tap } from 'rxjs';
import { tuiButtonOptionsProvider } from '@taiga-ui/core/components/button';
function TuiCarouselComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function TuiCarouselComponent_fieldset_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "fieldset", 5);
    i0.ɵɵlistener("waIntersectionObservee", function TuiCarouselComponent_fieldset_5_Template_fieldset_waIntersectionObservee_0_listener($event) {
      const i_r2 = i0.ɵɵrestoreView(_r1).index;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView($event[0] && ctx_r2.onIntersection($event[0], i_r2));
    });
    i0.ɵɵelementContainer(1, 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r4 = ctx.$implicit;
    const i_r2 = ctx.index;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabled", ctx_r2.isDisabled(i_r2))("ngStyle", ctx_r2.getStyle(ctx_r2.itemsCount));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", item_r4);
  }
}
class TuiCarouselDirective extends Observable {
  constructor() {
    super(subscriber => this.output$.subscribe(subscriber));
    this.el = tuiInjectElement();
    this.visible$ = inject(WA_PAGE_VISIBILITY);
    this.duration$ = new BehaviorSubject(0);
    this.running$ = merge(tuiTypedFromEvent(this.el, 'mouseenter').pipe(map(TUI_FALSE_HANDLER)), tuiTypedFromEvent(this.el, 'touchstart').pipe(map(TUI_FALSE_HANDLER)), tuiTypedFromEvent(this.el, 'touchend').pipe(map(TUI_TRUE_HANDLER)), tuiTypedFromEvent(this.el, 'mouseleave').pipe(map(TUI_TRUE_HANDLER)), this.visible$);
    this.output$ = combineLatest([this.duration$, this.running$]).pipe(tuiIfMap(([duration]) => interval(duration), values => values.every(Boolean)));
  }
  set duration(duration) {
    this.duration$.next(Number.isNaN(duration) ? this.duration$.value : duration);
  }
  static {
    this.ɵfac = function TuiCarouselDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiCarouselDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiCarouselDirective,
      inputs: {
        duration: "duration"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiCarouselDirective, [{
    type: Directive,
    args: [{
      standalone: true
    }]
  }], function () {
    return [];
  }, {
    duration: [{
      type: Input
    }]
  });
})();
class TuiCarouselAutoscroll {
  constructor() {
    this.tuiCarouselAutoscroll = inject(TuiCarouselDirective);
  }
  static {
    this.ɵfac = function TuiCarouselAutoscroll_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiCarouselAutoscroll)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiCarouselAutoscroll,
      selectors: [["", "tuiCarouselAutoscroll", ""]],
      outputs: {
        tuiCarouselAutoscroll: "tuiCarouselAutoscroll"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiCarouselAutoscroll, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiCarouselAutoscroll]'
    }]
  }], null, {
    tuiCarouselAutoscroll: [{
      type: Output
    }]
  });
})();
class TuiCarouselScroll {
  constructor() {
    this.el = tuiInjectElement();
    this.tuiCarouselScroll = tuiTypedFromEvent(this.el, 'wheel').pipe(filter(({
      deltaX
    }) => Math.abs(deltaX) > 20), throttleTime(500, tuiZonefreeScheduler()), map(({
      deltaX
    }) => Math.sign(deltaX)), tap(() => {
      // So we always have space to scroll and overflow-behavior saves us from back nav
      this.el.scrollLeft = 10;
    }));
  }
  static {
    this.ɵfac = function TuiCarouselScroll_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiCarouselScroll)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiCarouselScroll,
      selectors: [["", "tuiCarouselScroll", ""]],
      outputs: {
        tuiCarouselScroll: "tuiCarouselScroll"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiCarouselScroll, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiCarouselScroll]'
    }]
  }], null, {
    tuiCarouselScroll: [{
      type: Output
    }]
  });
})();
class TuiCarouselComponent {
  constructor() {
    this.el = tuiInjectElement();
    this.cdr = inject(ChangeDetectorRef);
    this.isMobile = inject(TUI_IS_MOBILE);
    this.directive = inject(TuiCarouselDirective);
    this.translate = 0;
    this.items = EMPTY_QUERY;
    this.transitioned = true;
    this.index = 0;
    this.draggable = false;
    this.itemsCount = 1;
    this.indexChange = new EventEmitter();
  }
  set indexSetter(index) {
    this.index = index;
    this.directive.duration = NaN;
  }
  next() {
    if (this.items && this.index === this.items.length - this.itemsCount) {
      return;
    }
    this.updateIndex(this.index + 1);
  }
  prev() {
    this.updateIndex(this.index - 1);
  }
  get transform() {
    const x = this.transitioned ? this.computedTranslate : this.translate;
    return `translateX(${100 * x}%)`;
  }
  getStyle(itemsCount) {
    const percent = `${100 / itemsCount}%`;
    return {
      flexBasis: percent,
      minWidth: percent,
      maxWidth: percent
    };
  }
  onTransitioned(transitioned) {
    this.transitioned = transitioned;
    if (!transitioned) {
      this.translate = this.computedTranslate;
    }
  }
  isDisabled(index) {
    return index < this.index || index > this.index + this.itemsCount;
  }
  onIntersection({
    intersectionRatio
  }, index) {
    if (intersectionRatio && intersectionRatio !== 1 && !this.transitioned) {
      this.updateIndex(index - Math.floor(this.itemsCount / 2));
    }
  }
  onScroll(delta) {
    if (!this.isMobile) {
      if (delta > 0) {
        this.next();
      } else {
        this.prev();
      }
    }
  }
  onPan(x) {
    if (!this.computedDraggable) {
      return;
    }
    const min = 1 - this.items.length / this.itemsCount;
    this.translate = tuiClamp(x / this.el.clientWidth + this.translate, min, 0);
  }
  onSwipe(direction) {
    if (direction === 'left') {
      this.next();
    } else if (direction === 'right') {
      this.prev();
    }
  }
  onAutoscroll() {
    this.updateIndex(this.index === this.items.length - 1 ? 0 : this.index + 1);
  }
  get computedTranslate() {
    return -this.index / this.itemsCount;
  }
  get computedDraggable() {
    return this.isMobile || this.draggable;
  }
  updateIndex(index) {
    this.index = tuiClamp(index, 0, this.items.length - 1);
    this.indexChange.emit(this.index);
    this.cdr.markForCheck();
  }
  static {
    this.ɵfac = function TuiCarouselComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiCarouselComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiCarouselComponent,
      selectors: [["tui-carousel"]],
      contentQueries: function TuiCarouselComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, TuiItem, 4, TemplateRef);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.items = _t);
        }
      },
      hostVars: 4,
      hostBindings: function TuiCarouselComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("touchstart", function TuiCarouselComponent_touchstart_HostBindingHandler() {
            return ctx.onTransitioned(false);
          })("touchend", function TuiCarouselComponent_touchend_HostBindingHandler() {
            return ctx.onTransitioned(true);
          })("mousedown", function TuiCarouselComponent_mousedown_HostBindingHandler() {
            return ctx.onTransitioned(false);
          })("mouseup.silent", function TuiCarouselComponent_mouseup_silent_HostBindingHandler() {
            return ctx.onTransitioned(true);
          }, false, i0.ɵɵresolveDocument);
        }
        if (rf & 2) {
          i0.ɵɵclassProp("_transitioned", ctx.transitioned)("_draggable", ctx.draggable);
        }
      },
      inputs: {
        draggable: "draggable",
        itemsCount: "itemsCount",
        indexSetter: [0, "index", "indexSetter"]
      },
      outputs: {
        indexChange: "indexChange"
      },
      standalone: true,
      features: [i0.ɵɵHostDirectivesFeature([{
        directive: TuiCarouselDirective,
        inputs: ["duration", "duration"]
      }]), i0.ɵɵStandaloneFeature],
      decls: 6,
      vars: 6,
      consts: [[4, "ngIf"], [1, "t-scroller", 3, "tuiCarouselScroll"], ["waIntersectionObserver", "", "waIntersectionRoot", "", "waIntersectionRootMargin", "100px 1000000px 100px -51%", "waIntersectionThreshold", "0,1", 1, "t-wrapper"], [1, "t-items", 3, "tuiCarouselAutoscroll", "tuiPan", "tuiSwipe"], ["class", "t-item", 3, "disabled", "ngStyle", "waIntersectionObservee", 4, "ngFor", "ngForOf"], [1, "t-item", 3, "waIntersectionObservee", "disabled", "ngStyle"], [3, "ngTemplateOutlet"]],
      template: function TuiCarouselComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, TuiCarouselComponent_ng_container_0_Template, 1, 0, "ng-container", 0);
          i0.ɵɵpipe(1, "async");
          i0.ɵɵelementStart(2, "div", 1);
          i0.ɵɵlistener("tuiCarouselScroll", function TuiCarouselComponent_Template_div_tuiCarouselScroll_2_listener($event) {
            return ctx.onScroll($event);
          });
          i0.ɵɵelementStart(3, "div", 2)(4, "div", 3);
          i0.ɵɵlistener("tuiCarouselAutoscroll", function TuiCarouselComponent_Template_div_tuiCarouselAutoscroll_4_listener() {
            return ctx.onAutoscroll();
          })("tuiPan", function TuiCarouselComponent_Template_div_tuiPan_4_listener($event) {
            return ctx.onPan($event[0]);
          })("tuiSwipe", function TuiCarouselComponent_Template_div_tuiSwipe_4_listener($event) {
            return ctx.onSwipe($event.direction);
          });
          i0.ɵɵtemplate(5, TuiCarouselComponent_fieldset_5_Template, 2, 3, "fieldset", 4);
          i0.ɵɵelementEnd()()();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 4, ctx.items.changes));
          i0.ɵɵadvance(4);
          i0.ɵɵstyleProp("transform", ctx.transform);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngForOf", ctx.items);
        }
      },
      dependencies: [AsyncPipe, NgForOf, NgIf, NgStyle, NgTemplateOutlet, TuiCarouselAutoscroll, TuiCarouselScroll, TuiPan, TuiSwipe, i2.WaIntersectionObserverDirective, i2.WaIntersectionObservee, i2.WaIntersectionRoot],
      styles: ["[_nghost-%COMP%]{position:relative;display:block;overflow:hidden}._draggable[_nghost-%COMP%]{-webkit-user-select:none;user-select:none}._draggable[_nghost-%COMP%]:hover{cursor:grab}._draggable[_nghost-%COMP%]:active{cursor:grabbing}.t-items[_ngcontent-%COMP%]{display:flex}._transitioned[_nghost-%COMP%]   .t-items[_ngcontent-%COMP%]{transition-property:transform;transition-duration:var(--tui-duration, .3s);transition-timing-function:ease-in-out}  [tuiCarouselButtons] [tuiIconButton]{border-radius:100%}.t-item[_ngcontent-%COMP%]{display:flex;flex-direction:column;justify-content:center;padding:var(--tui-carousel-padding, 0 1.25rem);flex:1;min-inline-size:100%;max-inline-size:100%;box-sizing:border-box;border:none;margin:0}.t-wrapper[_ngcontent-%COMP%]{position:sticky;left:0;right:0;min-inline-size:100%;overflow:hidden}.t-scroller[_ngcontent-%COMP%]{scrollbar-width:none;-ms-overflow-style:none;display:flex;overflow:auto;overscroll-behavior-x:none}.t-scroller[_ngcontent-%COMP%]::-webkit-scrollbar, .t-scroller[_ngcontent-%COMP%]::-webkit-scrollbar-thumb{display:none}.t-scroller[_ngcontent-%COMP%]:before, .t-scroller[_ngcontent-%COMP%]:after{content:\"\";display:block;min-inline-size:1rem}"],
      changeDetection: 0
    });
  }
}
__decorate([tuiPure], TuiCarouselComponent.prototype, "getStyle", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiCarouselComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'tui-carousel',
      imports: [AsyncPipe, NgForOf, NgIf, NgStyle, NgTemplateOutlet, TuiCarouselAutoscroll, TuiCarouselScroll, TuiPan, TuiSwipe, WaIntersectionObserver],
      changeDetection: ChangeDetectionStrategy.OnPush,
      hostDirectives: [{
        directive: TuiCarouselDirective,
        inputs: ['duration']
      }],
      host: {
        '[class._transitioned]': 'transitioned',
        '[class._draggable]': 'draggable',
        '(touchstart)': 'onTransitioned(false)',
        '(touchend)': 'onTransitioned(true)',
        '(mousedown)': 'onTransitioned(false)',
        '(document:mouseup.silent)': 'onTransitioned(true)'
      },
      template: "<ng-container *ngIf=\"items.changes | async\" />\n<div\n    class=\"t-scroller\"\n    (tuiCarouselScroll)=\"onScroll($event)\"\n>\n    <div\n        waIntersectionObserver\n        waIntersectionRoot\n        waIntersectionRootMargin=\"100px 1000000px 100px -51%\"\n        waIntersectionThreshold=\"0,1\"\n        class=\"t-wrapper\"\n    >\n        <div\n            class=\"t-items\"\n            [style.transform]=\"transform\"\n            (tuiCarouselAutoscroll)=\"onAutoscroll()\"\n            (tuiPan)=\"onPan($event[0])\"\n            (tuiSwipe)=\"onSwipe($event.direction)\"\n        >\n            <fieldset\n                *ngFor=\"let item of items; let i = index\"\n                class=\"t-item\"\n                [disabled]=\"isDisabled(i)\"\n                [ngStyle]=\"getStyle(itemsCount)\"\n                (waIntersectionObservee)=\"$event[0] && onIntersection($event[0], i)\"\n            >\n                <ng-container [ngTemplateOutlet]=\"item\" />\n            </fieldset>\n        </div>\n    </div>\n</div>\n",
      styles: [":host{position:relative;display:block;overflow:hidden}:host._draggable{-webkit-user-select:none;user-select:none}:host._draggable:hover{cursor:grab}:host._draggable:active{cursor:grabbing}.t-items{display:flex}:host._transitioned .t-items{transition-property:transform;transition-duration:var(--tui-duration, .3s);transition-timing-function:ease-in-out}::ng-deep [tuiCarouselButtons] [tuiIconButton]{border-radius:100%}.t-item{display:flex;flex-direction:column;justify-content:center;padding:var(--tui-carousel-padding, 0 1.25rem);flex:1;min-inline-size:100%;max-inline-size:100%;box-sizing:border-box;border:none;margin:0}.t-wrapper{position:sticky;left:0;right:0;min-inline-size:100%;overflow:hidden}.t-scroller{scrollbar-width:none;-ms-overflow-style:none;display:flex;overflow:auto;overscroll-behavior-x:none}.t-scroller::-webkit-scrollbar,.t-scroller::-webkit-scrollbar-thumb{display:none}.t-scroller:before,.t-scroller:after{content:\"\";display:block;min-inline-size:1rem}\n"]
    }]
  }], null, {
    items: [{
      type: ContentChildren,
      args: [TuiItem, {
        read: TemplateRef
      }]
    }],
    draggable: [{
      type: Input
    }],
    itemsCount: [{
      type: Input
    }],
    indexChange: [{
      type: Output
    }],
    indexSetter: [{
      type: Input,
      args: ['index']
    }],
    getStyle: []
  });
})();
class TuiCarouselButtons {
  static {
    this.ɵfac = function TuiCarouselButtons_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiCarouselButtons)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiCarouselButtons,
      selectors: [["", "tuiCarouselButtons", ""]],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([tuiButtonOptionsProvider({
        appearance: 'secondary',
        size: 'm'
      })])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiCarouselButtons, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiCarouselButtons]',
      providers: [tuiButtonOptionsProvider({
        appearance: 'secondary',
        size: 'm'
      })]
    }]
  }], null, null);
})();
const TuiCarousel = [TuiItem, TuiCarouselComponent, TuiCarouselDirective, TuiCarouselAutoscroll, TuiCarouselButtons, TuiCarouselScroll];

/**
 * Generated bundle index. Do not edit.
 */

export { TuiCarousel, TuiCarouselAutoscroll, TuiCarouselButtons, TuiCarouselComponent, TuiCarouselDirective, TuiCarouselScroll };
