import { inject, Injectable } from '@angular/core';
import {
	collection,
	collectionCount,
	doc,
	getDoc,
	getDocs,
	limit,
	query,
	setDoc,
	startAfter,
	updateDoc,
} from '@angular/fire/firestore';
import {
	BaseActivityService,
	UpdateOptions,
} from '@context/frontend/api-client';
import { ContentService } from '@context/frontend/content';
import { MediaService } from '@context/frontend/media';
import { NotificationService } from '@context/frontend/notification';
import { Fetchable, PaginatedObject } from '@context/frontend/pagination';
import { ActivityType } from '@context/shared/types/activity';
import {
	Content,
	createThumbnail,
	PresetCompressions,
	Thumbnail,
} from '@context/shared/types/common';
import { ContentLog, createLog } from '@context/shared/types/log';
import { ContentMedia, isMediaImage } from '@context/shared/types/media';
import { getName, User } from '@context/shared/types/user';
import { sanitize } from '@context/shared/utils';
import {
	collectionGroup,
	CollectionReference,
	DocumentData,
	DocumentReference,
	DocumentSnapshot,
	Query,
	Timestamp,
	UpdateData,
} from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';
import { v4 } from 'uuid';

@Injectable({ providedIn: 'root' })
export class LogService
	extends BaseActivityService<ContentLog>
	implements Fetchable<ContentLog>
{
	private static readonly CollectionId = 'logs';

	readonly mediaService = inject(MediaService);
	readonly contentService = inject(ContentService);
	readonly notificationService = inject(NotificationService);

	async create(
		parentRef: DocumentReference<Content>,
		log: Partial<ContentLog> & { files?: File[] },
	) {
		const files = log.files;
		delete log.files;

		const now = Timestamp.now();
		const payload = createLog(log) as ContentLog;

		payload.createdAt = now;
		payload.updatedAt = now;
		payload.deletedAt = null;

		payload.id = v4();

		const collectionLog = collection(parentRef, LogService.CollectionId);
		const logRef = doc(
			collectionLog,
			payload.id,
		) as DocumentReference<ContentLog>;

		const parent = (await getDoc(parentRef)).data() as Content;

		await setDoc(logRef, sanitize(payload)).then(() => {
			// if the parent content is not owned by the log creator, send notification
			if (parent.createdBy.id !== this.user.value?.id) {
				this.sendNotification('user-added-log', parent, {
					name: payload.name,
					id: payload.id,
				});
			}
		});

		if (files?.length) {
			const { thumbnails, media, firstImageMedia } =
				await this.createLogMedia(
					files,
					parentRef,
					logRef,
					payload.thumbnails,
				);

			if (firstImageMedia) {
				await this.contentService.setContentMediaDerivedThumbnail({
					derived: 'log',
					content: parent,
					ref: firstImageMedia.ref,
					media: firstImageMedia.data,
				});
			}

			await this.update(
				parentRef,
				{ thumbnails, media: media.map((m) => m.ref) },
				logRef,
				{ addActivityLog: false },
			);
		}

		if (payload.createdBy || this.user.value?.id) {
			await this.logActivity({
				entity: logRef as DocumentReference<ContentLog>,
				createdBy:
					(payload.createdBy as DocumentReference<User>) ??
					this.user.value?.id,
				activity: { type: 'create' },
			});
		}

		return payload;
	}

	/**
	 * The logRef is required here so we can easily update the reference without needing to make
	 * a large id query against all of the collectionGroups.
	 *
	 * @param payload.files The new files that will be uploaded onto the log
	 */
	async update(
		parentRef: DocumentReference<Content>,
		payload: Partial<
			ContentLog & {
				files?: File[];
				removedMedia?: DocumentReference<ContentMedia>[];
			}
		>,
		logRef: DocumentReference<ContentLog>,
		options: UpdateOptions,
	) {
		const removedMedia = payload.removedMedia;
		delete payload.removedMedia;

		// detaches the log from the media item so the media item can be managed independently now
		if (removedMedia?.length) {
			payload.thumbnails = payload.thumbnails
				? [
						...payload.thumbnails.filter(
							(t) =>
								!removedMedia.some((m) => m.id === t.ref?.id),
						),
					]
				: [];

			await Promise.all(
				removedMedia.map((m) =>
					this.mediaService.update({ log: null }, m, {
						addActivityLog: true,
						type: 'update',
					}),
				),
			);
		}

		const files = payload.files;
		delete payload.files;

		if (files?.length) {
			const { thumbnails, media } = await this.createLogMedia(
				files,
				parentRef,
				logRef,
				payload.thumbnails,
			);
			payload.thumbnails = thumbnails;
			payload.media = media.map((m) => m.ref);
		}

		payload.updatedAt = Timestamp.now();
		const value = sanitize(payload);
		await updateDoc(logRef, value as UpdateData<ContentLog>);

		if (this.user.value?.id) {
			await this.logActivity({
				entity: logRef,
				activity: { type: options.type as ActivityType },
				createdBy: this.user.value?.id,
			});
		}

		return value;
	}

	/**
	 * The logRef is required here so we ca easily "delete" the reference without needing to
	 * make a large id query against all of the collectionGroups
	 */
	async delete(
		parent: Content,
		logRef: DocumentReference<ContentLog>,
		log?: ContentLog,
	) {
		if (!log) log = (await getDoc(logRef)).data() as ContentLog;

		const now = Timestamp.now();
		const batch: Promise<any>[] = [
			updateDoc(
				logRef,
				sanitize({
					updatedAt: now,
					deletedAt: now,
				}) as UpdateData<ContentLog>,
			),
		];

		if (this.user.value?.id) {
			batch.push(
				this.logActivity({
					entity: logRef,
					activity: { type: 'delete' },
					createdBy: this.user.value?.id,
				}),
			);
		}

		if (log.media.length) {
			batch.push(
				...log.media.map((mediaRef) =>
					this.mediaService.delete(
						parent,
						mediaRef as DocumentReference<ContentMedia>,
					),
				),
			);
		}

		// empty object to ensure deletion success
		return Promise.all(batch).then(() => ({}));
	}

	async archive(
		parentRef: DocumentReference<Content>,
		logRef: DocumentReference<ContentLog>,
		log?: ContentLog,
	) {
		if (!log) log = (await getDoc(logRef)).data() as ContentLog;

		const parent = (await getDoc(parentRef)).data() as Content;

		const now = Timestamp.now();
		const batch: Promise<any>[] = [
			this.update(parentRef, { archivedAt: now }, logRef, {
				addActivityLog: true,
				type: 'archive',
			}).then(() => {
				if (parent.createdBy.id !== this.user.value?.id) {
					this.sendNotification('user-archived-log', parent, log);
				}
			}),
		];

		batch.push(
			...log.media.map((mediaRef) =>
				this.mediaService.archive(
					parent,
					mediaRef as DocumentReference<ContentMedia>,
				),
			),
		);

		return Promise.all(batch).then(([payload]) => payload);
	}

	async unarchive(
		parentRef: DocumentReference<Content>,
		logRef: DocumentReference<ContentLog>,
		log?: ContentLog,
	) {
		if (!log) log = (await getDoc(logRef)).data() as ContentLog;

		const batch: Promise<any>[] = [
			this.update(parentRef, { archivedAt: null }, logRef, {
				addActivityLog: true,
				type: 'unarchive',
			}),
		];

		batch.push(
			...log.media.map((mediaRef) =>
				this.mediaService.unarchive(
					mediaRef as DocumentReference<ContentMedia>,
				),
			),
		);

		return Promise.all(batch).then(([payload]) => payload);
	}

	async fetch(
		paginated?: PaginatedObject<ContentLog>,
	): Promise<PaginatedObject<ContentLog>> {
		if (!paginated) paginated = new PaginatedObject<ContentLog>();

		let mediaQuery: Query<ContentLog> | null = null;
		if (paginated.parentRef) {
			mediaQuery = query(
				collection(
					paginated.parentRef as DocumentReference<Content>,
					LogService.CollectionId,
				) as CollectionReference<ContentLog, DocumentData>,
			);
		} else if (this.organizationId.value) {
			mediaQuery = query(
				collectionGroup(
					this.firestore,
					LogService.CollectionId,
				) as CollectionReference<ContentLog>,
			);
		} else {
			throw new Error('Collection could not be determined');
		}

		if (!paginated.totalElements) {
			paginated.totalElements = await firstValueFrom(
				collectionCount(query(mediaQuery, ...paginated.constraints)),
			);
		}

		// if the object already contains the data, don't fetch again
		if (paginated.page) return paginated;

		const constraints = [...paginated.constraints];
		const lastDoc = paginated.previousPage?.last ?? null;
		if (lastDoc) constraints.push(startAfter(lastDoc));
		if (paginated.pageSize) constraints.push(limit(paginated.pageSize));
		const snapshot = await getDocs(query(mediaQuery, ...constraints));

		let last: DocumentSnapshot | null = null;
		const items = snapshot.docs.map((d) => {
			last = d;
			return { data: d.data(), ref: d.ref, id: d.id };
		});

		paginated.pages = Object.assign({}, paginated.pages, {
			[paginated.currentPage as number]: { last, items },
		});

		return paginated;
	}

	sendNotification(
		type: 'user-added-log' | 'user-archived-log',
		parent: Content,
		log: Pick<ContentLog, 'id' | 'name'>,
	) {
		const user = this.user.value as User;
		this.notificationService.send(
			{
				createdById: user.id,
				type,
				args: {
					id: log.id,
					name: log.name,
					user: getName(user),
					content: parent.name,
					contentId: parent.id,
				},
			},
			parent.createdBy.id,
		);
	}

	/**
	 * Creates the media items for the log being created and also generates the
	 * thumbnails based on the media created
	 */
	private async createLogMedia(
		files: File[],
		parentRef: DocumentReference<Content>,
		logRef: DocumentReference<ContentLog>,
		thumbnails: Thumbnail[] = [],
	) {
		if (!files) return { thumbnails: [], media: [] };

		const batch: Promise<{
			data: ContentMedia;
			ref: DocumentReference<ContentMedia>;
		} | null>[] = [];

		const errors = new Map<number, File>();
		files?.forEach((file, index) =>
			batch.push(
				this.mediaService
					.create(parentRef, file as File, { log: logRef })
					.catch((error) => {
						errors.set(index, file);
						console.error(
							'There was an issue uploading the media',
							error,
						);
						return null;
					}),
			),
		);

		if (errors.size)
			console.error(
				'There was an issue uploading all of the media',
				errors,
			);

		const { media } = await Promise.all(batch)
			.catch((error) => {
				console.error(error);
				throw new Error('There was an issue with the requests');
			})
			.then((media) => ({
				media: media.filter((m) => !!m), // removes failed uploads
				errors,
			}));

		// todo: make this a choice by the user eventually, but defaulting to first that matches
		const firstImageMedia = media.find((m) => isMediaImage(m.data.type));
		if (firstImageMedia) {
			// remove any other previous log derived thumbnail since there should only be one
			thumbnails = [...thumbnails.filter((t) => t.derived !== 'media')];
			thumbnails.push(
				createThumbnail({
					storagePath: firstImageMedia.data.storagePath,
					maxResolution: PresetCompressions.max.maxWidthOrHeight,
					derived: 'media',
					ref: firstImageMedia.ref,
				}),
				createThumbnail({
					storagePath: firstImageMedia.data.thumbnailPath as string,
					maxResolution:
						PresetCompressions.thumbnail.maxWidthOrHeight,
					derived: 'media',
					ref: firstImageMedia.ref,
				}),
			);
		}

		return { thumbnails, media, firstImageMedia };
	}
}
