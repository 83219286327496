import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { RelativeTimePipe } from '@context/frontend/common';
import { getUaDevice } from '@context/frontend/native/platform';
import { ContentLog } from '@context/shared/types/log';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiIcon } from '@taiga-ui/core';
import { LogThumbnailComponent } from '../log-thumbnail/log-thumbnail.component';
import { DocumentReference } from 'firebase/firestore';
import { Content } from '@context/shared/types/common';
import { getDoc } from '@angular/fire/firestore';
import { TuiBadge } from '@taiga-ui/kit';

@Component({
	standalone: true,
	imports: [
		RelativeTimePipe,
		TranslocoModule,
		TuiIcon,
		LogThumbnailComponent,
		TuiBadge,
	],
	selector: 'ctx-log-row',
	templateUrl: 'log-row.component.html',
	styleUrl: 'log-row.component.scss',
})
export class LogRowComponent implements OnInit {
	@HostBinding('attr.device')
	readonly device = getUaDevice();

	@Input()
	log!: ContentLog;

	@Input()
	logRef!: DocumentReference<ContentLog>;

	parent: Content | null = null;

	ngOnInit() {
		if (this.logRef.parent) {
			// parent == collection // parents parent == document
			getDoc(
				this.logRef.parent.parent as DocumentReference<Content>,
			).then((d) => {
				this.parent = d.data() as Content;
			});
		}
	}
}
