import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	inject,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { TuiIcon } from '@taiga-ui/core';
import * as Mousetrap from 'mousetrap';

const IconKeys = ['command'];
type IconKey = (typeof IconKeys)[number] | (string & {});

const IconKeyMap: { [key in IconKey]?: string } = {
	command: '@tui.command',
};

@Component({
	standalone: true,
	imports: [TuiIcon],
	selector: 'ctx-hotkey',
	styleUrl: 'hotkey.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		@if (icon) {
			<tui-icon [icon]="icon" />
		} @else {
			{{ text }}
		}
	`,
})
export class HotkeyComponent implements OnInit, OnDestroy {
	@Input()
	key!: IconKey;

	@Input()
	allowEvents = true;

	private readonly cdRef = inject(ChangeDetectorRef);

	icon?: string;
	text?: string;

	keydownEvent!: Mousetrap.MousetrapInstance;
	keyupEvent!: Mousetrap.MousetrapInstance;
	abortController = new AbortController();

	constructor(private readonly el: ElementRef<HTMLElement>) {}

	ngOnInit() {
		this.icon = IconKeyMap[this.key];
		if (!this.icon) this.text = this.key;

		if (this.allowEvents) {
			this.setupEvents();
		}
	}

	setupEvents() {
		this.keydownEvent = Mousetrap.bind(
			this.key.toLowerCase(),
			this.setPress.bind(this, true),
			'keydown',
		);

		this.keyupEvent = Mousetrap.bind(
			this.key.toLowerCase(),
			this.setPress.bind(this, false),
			'keyup',
		);

		document.addEventListener('focusin', this.setPress.bind(this, false), {
			signal: this.abortController.signal,
		});
	}

	setPress(value: boolean) {
		if (value) this.el.nativeElement.classList.add('pressed');
		else this.el.nativeElement.classList.remove('pressed');
		this.cdRef.detectChanges();
	}

	ngOnDestroy() {
		this.keyupEvent.unbind([this.key.toLowerCase()]);
		this.keydownEvent.unbind([this.key.toLowerCase()]);
		this.abortController.abort();
	}
}
