<ctx-log-thumbnail [log]="log"></ctx-log-thumbnail>

<div class="log-row__information">
	<p class="margin-0">
		{{ log.name }}
	</p>

	<small class="margin-0">
		{{
			'data.last-edited-at'
				| transloco: { time: log.updatedAt | relativeTime }
		}}
	</small>
</div>

@if (parent) {
	<div class="log-row__actions">
		<tui-badge appearance="info">
			@switch (parent.type) {
				@case ('file') {
					<tui-icon icon="@tui.sprout"></tui-icon>
				}
				@case ('folder') {
					<tui-icon icon="@tui.folder"></tui-icon>
				}
			}

			<span class="log-row__actions-badge-text">
				{{ parent.name }}
			</span>
		</tui-badge>

		<ng-content></ng-content>
	</div>
}
