<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<section tuiContent>
	@if (loading) {
		<tui-loader size="xl" />
	} @else if (form) {
		<form [formGroup]="form" (ngSubmit)="onSubmit()">
			@if (context.data.contentPreview && context.data.content) {
				<span>
					<p class="font-size-small margin-0">
						{{ 'content.label' | transloco }}*
					</p>

					<ctx-content-row
						class="margin-bottom-2"
						[content]="context.data.content"
						[asTableRow]="false"
						[interactable]="false"
						size="s"
					></ctx-content-row>
				</span>
			}

			<label tuiLabel>
				{{ 'log.log-name' | transloco }}*

				<tui-textfield tuiTextfieldSize="m">
					<input
						formControlName="name"
						tuiTextfield
						type="text"
						[placeholder]="'log.log-name-placeholder' | transloco"
					/>
				</tui-textfield>

				<tui-error
					formControlName="name"
					[error]="['required'] | tuiFieldError | async"
				/>
			</label>

			<label tuiLabel>
				{{ 'content.owner' | transloco }}*

				<tui-textfield tuiTextfieldSize="m">
					<input
						formControlName="createdBy"
						tuiTextfield
						type="text"
					/>
				</tui-textfield>
			</label>

			<label tuiLabel>
				{{ 'log.log-entry' | transloco }}*

				<tui-textarea
					tuiTextfieldSize="m"
					formControlName="description"
					[tuiTextfieldLabelOutside]="true"
					[expandable]="true"
					[maxLength]="maxLength"
					tuiAutoFocus
				></tui-textarea>

				<tui-error
					[error]="[''] | tuiFieldError | async"
					formControlName="description"
				/>
			</label>

			<div class="margin-top-1">
				<label class="font-size-small">
					{{ 'data.media' | transloco }}
				</label>

				<label tuiInputFiles>
					<input
						#validator="tuiInputFilesValidator"
						tuiInputFiles
						[accept]="acceptedFiles"
						formControlName="files"
						[multiple]="true"
					/>
				</label>

				<tui-files class="margin-top-1">
					@if (existingFiles.length) {
						@for (file of existingFiles; track file) {
							@if (file.state === 'deleted') {
								<tui-file
									*tuiItem
									state="deleted"
									[file]="file"
									(remove)="onRemovedFileRestore(file)"
								>
									<small>
										{{
											'media.removing-media-item'
												| transloco
										}}
									</small>
								</tui-file>
							} @else {
								<tui-file
									*tuiItem
									[leftContent]="existing"
									[file]="file"
									(remove)="onExistingFileRemove(file)"
								>
									<small>
										{{
											'media.existing-media-item'
												| transloco
										}}
									</small>
								</tui-file>
							}
						}
					}

					@for (
						file of form.get('files')?.valueChanges | async;
						track file
					) {
						@if (loading) {
							<!-- Normal indicates the file was uploaded successfully -->
							<tui-file
								*tuiItem
								state="loading"
								[showDelete]="false"
								[file]="
									(file
										| tuiFileRejected: validator
										| async) || file
								"
								(remove)="onFileRemove(file)"
							>
								<small>
									{{ 'media.uploading' | transloco }}
								</small>
							</tui-file>
						} @else {
							<!-- If the file hasn't been designated a state, it's idling -->
							<tui-file
								*tuiItem
								[leftContent]="idle"
								[file]="
									(file
										| tuiFileRejected: validator
										| async) || file
								"
								(remove)="onFileRemove(file)"
							>
								<small>
									{{ 'media.waiting-for-upload' | transloco }}
								</small>
							</tui-file>
						}
					}
				</tui-files>

				<tui-error
					[error]="['maxLength'] | tuiFieldError | async"
					formControlName="files"
				/>
			</div>
		</form>
	}
</section>

<footer tuiFooter>
	<button tuiButton appearance="outline" (click)="onDismiss()">
		{{ 'action.cancel' | transloco }}
	</button>

	<button tuiButton appearance="accent" (click)="onSubmit()" [loading]="busy">
		@if (context.data.log) {
			{{ 'action.save' | transloco }}
		} @else {
			{{ 'action.create' | transloco }}
		}
	</button>
</footer>

<ng-template #idle>
	<tui-icon icon="@tui.clock" />
</ng-template>

<ng-template #existing>
	<tui-icon [style.color]="'var(--tui-status-positive)'" icon="@tui.cloud" />
</ng-template>
