import { ContentLog } from '../types';

export function createLog(payload: Partial<ContentLog>): Partial<ContentLog> {
	if (!payload.name) throw new Error('A name is required');

	return {
		...payload,
		name: payload.name,
		description: payload.description ?? null,
		media: payload.media ?? [],
		thumbnails: payload.thumbnails ?? [],
		archivedAt: payload.archivedAt ?? null,
	};
}
