import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { AlertService } from '@context/frontend/alert';
import { UserRowComponent } from '@context/frontend/user';
import { Content } from '@context/shared/types/common';
import { User } from '@context/shared/types/user';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiButton } from '@taiga-ui/core';
import { TuiButtonLoading } from '@taiga-ui/kit';
import { DocumentReference } from 'firebase/firestore';
import { ContentService } from '../../../services';

@Component({
	standalone: true,
	imports: [UserRowComponent, TuiButton, TuiButtonLoading, TranslocoModule],
	selector: 'ctx-share-user-row',
	templateUrl: 'share-user-row.component.html',
	styles: `
		:host {
			display: contents;
		}
	`,
})
export class ShareUserRowComponent {
	@Input() user!: User;

	@Input() userRef!: DocumentReference<User>;

	@Input() content!: Content;

	@Output() remove = new EventEmitter<DocumentReference<User>>();

	readonly contentService = inject(ContentService);
	readonly alert = inject(AlertService);

	busy = false;

	onRemove() {
		this.busy = true;
		return this.contentService
			.removeShareWithUser(this.content, [this.userRef])
			.then(() => {
				this.alert.open('generic-success').subscribe();
				this.remove.emit(this.userRef);
			})
			.catch((error) => {
				this.alert.open('generic-error').subscribe();
				console.error(error);
				throw new Error('There was an issue removing the shared user');
			})
			.finally(() => {
				this.busy = false;
			});
	}
}
