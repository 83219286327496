import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	HostBinding,
	inject,
	Input,
	OnInit,
} from '@angular/core';
import { StorageService } from '@context/frontend/storage';
import { Content } from '@context/shared/types/common';
import { getPreferredThumbnail } from '@context/shared/types/media';
import {
	TuiFallbackSrcPipe,
	TuiHint,
	TuiSizeL,
	TuiSizeS
} from '@taiga-ui/core';
import { TuiAvatar } from '@taiga-ui/kit';

const IconMap: { [key in 'folder' | 'file']: string } = {
	file: '@tui.sprout',
	folder: '@tui.folder',
};

@Component({
	standalone: true,
	imports: [TuiAvatar, TuiFallbackSrcPipe, AsyncPipe, TuiHint],
	selector: 'ctx-content-thumbnail',
	templateUrl: 'content-thumbnail.component.html',
	styleUrl: 'content-thumbnail.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentThumbnailComponent implements OnInit {
	@Input()
	content!: Content;

	@HostBinding('attr.size')
	@Input()
	size: TuiSizeS | TuiSizeL = 'l';

	@Input()
	badgeIcon: string | null = null;

	@Input()
	badgeHint: string | null = null;

	readonly storageService = inject(StorageService);
	readonly cdRef = inject(ChangeDetectorRef);

	thumbnailUrl: string | null = null;

	get icon() {
		return IconMap[this.content.type];
	}

	ngOnInit() {
		this.fetchThumbnail().then((res) => {
			this.thumbnailUrl = res;
			this.cdRef.detectChanges();
		});
	}

	fetchThumbnail() {
		if (!this.content.thumbnails?.length) return Promise.resolve(null);

		const thumbnail = getPreferredThumbnail(this.content.thumbnails, {
			resolution: 256,
		});

		if (!thumbnail) return Promise.resolve(null);

		const storagePath = this.storageService.getReference(
			thumbnail.storagePath,
		);
		return this.storageService.getDownloadUrl(storagePath);
	}
}
