import { Component, inject, OnInit } from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
	DestroyService,
	DialogBase,
	DialogOptions,
} from '@context/frontend/common';
import { GlobalSearchService } from '@context/frontend/search';
import { TablePlaceholderComponent } from '@context/frontend/table';
import { AlgoliaGlobal } from '@context/shared/types/search';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiLoader, TuiTextfield } from '@taiga-ui/core';
import { TuiSearch } from '@taiga-ui/layout';
import { debounceTime, takeUntil, tap } from 'rxjs';
import { GlobalSearchFileComponent } from '../global-search-file/global-search-file.component';
import { GlobalSearchFolderComponent } from '../global-search-folder/global-search-folder.component';
import { GlobalSearchMediaComponent } from '../global-search-media/global-search-media.component';
import { GlobalSearchUserComponent } from '../global-search-user/global-search-user.component';

@Component({
	standalone: true,
	imports: [
		TuiLoader,
		TranslocoModule,
		GlobalSearchUserComponent,
		GlobalSearchMediaComponent,
		GlobalSearchFolderComponent,
		GlobalSearchFileComponent,
		TablePlaceholderComponent,
		TuiSearch,
		TuiTextfield,
		FormsModule,
		ReactiveFormsModule,
	],
	selector: 'ctx-global-search-dialog',
	templateUrl: 'global-search-dialog.component.html',
	providers: [DestroyService],
})
export class GlobalSearchDialogComponent extends DialogBase implements OnInit {
	static override readonly DialogOptions = {
		label: 'action.search',
		size: 'fullscreen',
	} as DialogOptions;

	readonly destroy$ = inject(DestroyService);
	readonly router = inject(Router);
	readonly searchService = inject(GlobalSearchService);

	readonly search = new FormGroup({
		term: new FormControl(''),
	});

	data: AlgoliaGlobal<any>[] = [];
	totalElements = 0;

	loading = false;

	ngOnInit() {
		this.initialize();
	}

	initialize() {
		this.search
			.get('term')
			?.valueChanges.pipe(
				takeUntil(this.destroy$),
				tap(() => {
					this.loading = true;
				}),
				debounceTime(500),
			)
			.subscribe((term) => {
				if (!term) {
					this.data = [];
					this.loading = false;
					return;
				}

				this.fetchData(term);
			});
	}

	fetchData(term: string) {
		this.loading = true;
		this.searchService
			.fetch(term)
			.then((res: any) => {
				this.totalElements = res.nbHits ?? 0;
				this.data = res.hits ?? [];
			})
			.finally(() => {
				this.loading = false;
			});
	}

	onSelect(path: string[]) {
		this.router.navigate(path);
		this.onDismiss();
	}
}
