import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	HostBinding,
	inject,
	OnInit,
} from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import {
	DestroyService,
	DialogBase,
	DialogOptions,
} from '@context/frontend/common';
import { getUaDevice } from '@context/frontend/native/platform';
import {
	PaginatedObject,
	PaginatedPageItem,
	PaginationPage,
} from '@context/frontend/pagination';
import { TableComponent } from '@context/frontend/table';
import { AuthService } from '@context/frontend/user';
import { Content } from '@context/shared/types/common';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiAutoFocus } from '@taiga-ui/cdk';
import { TuiButton, TuiLoader, TuiTextfield } from '@taiga-ui/core';
import { TuiButtonLoading, TuiRadio } from '@taiga-ui/kit';
import { DocumentReference, where } from 'firebase/firestore';
import { debounceTime, takeUntil, tap } from 'rxjs';
import { ContentRowComponent } from '../../components';
import { ContentService } from '../../services';

@Component({
	standalone: true,
	imports: [
		TuiLoader,
		TuiButton,
		TuiButtonLoading,
		TranslocoModule,
		TableComponent,
		TuiTextfield,
		FormsModule,
		ReactiveFormsModule,
		ContentRowComponent,
		TuiRadio,
		TuiAutoFocus,
	],
	selector: 'ctx-select-content-dialog',
	templateUrl: 'select-content-dialog.component.html',
	styleUrl: 'select-content-dialog.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService],
})
export class SelectContentDialogComponent
	extends DialogBase<
		PaginatedPageItem<Content> | null,
		{ type: 'file' | 'folder' | 'all' }
	>
	implements OnInit
{
	static override readonly DialogOptions = {
		label: 'content.select-content-label',
		size: getUaDevice() === 'mobile' ? 'fullscreen' : 'm',
	} as DialogOptions;

	@HostBinding('attr.device')
	readonly device = getUaDevice();

	private readonly contentService = inject(ContentService);
	private readonly cdRef = inject(ChangeDetectorRef);
	private readonly destroy$ = inject(DestroyService);
	private readonly authService = inject(AuthService);

	form: FormGroup | null = null;

	searching = false;
	loading = false;
	busy = false;

	readonly placeholder = {
		icon: '@tui.search',
		header: 'data.search-placeholder-header',
		content: 'data.search-placeholder-content',
	};

	readonly placeholderContent = {
		icon: '@tui.folder',
		header: 'content.table-placeholder-header',
		content: 'content.table-placeholder-select-content',
	};

	content = new PaginatedObject<Content>();

	filteredContent: PaginatedPageItem<Content>[] = [];

	ngOnInit() {
		this.setupForm();
	}

	async setupForm() {
		this.loading = true;

		const constraints = [
			where('shared', 'array-contains', this.authService.userRef),
			where('archivedAt', '==', null),
		];

		const type = this.context.data.type ?? 'all';
		if (type !== 'all') constraints.push(where('type', '==', type));

		this.content = new PaginatedObject({
			sortBy: 'name',
			sortDir: 'asc',
			pageSize: 100,
			constraints,
		});

		await this.onLoadContent();

		this.form = new FormGroup({
			search: new FormControl(),
			selected: new FormControl(null, Validators.required),
		});

		this.loading = false;
		this.cdRef.detectChanges();

		this.form
			.get('search')
			?.valueChanges.pipe(
				tap(() => {
					this.searching = true;
				}),
				takeUntil(this.destroy$),
				debounceTime(250),
			)
			.subscribe((term) => {
				this.searching = false;
				this.onSetFiltered(term);
			});
	}

	onLoadContent = () =>
		this.contentService.fetch(this.content).then((value) => {
			this.content.update(value);
			this.filteredContent = this.onFilterContent(
				this.form?.get('search')?.value,
				value.page,
			);
		});

	onSetFiltered(term: string) {
		this.filteredContent = this.onFilterContent(term, this.content.page);
		this.cdRef.detectChanges();
	}

	onFilterContent = (
		term: string | null,
		page: PaginationPage<Content> | null,
	): PaginatedPageItem<Content>[] =>
		(page?.items ?? []).reduce((value, current) => {
			term = term?.trim().toLowerCase() ?? '';
			const content = current.data;

			if (
				!term ||
				content.name.toLowerCase().includes(term) ||
				content.description?.toLowerCase().includes(term)
			) {
				value.push(current);
			}

			return value;
		}, [] as PaginatedPageItem<Content>[]);

	onContentRemoved(contentRef: DocumentReference<Content>) {
		const items = this.content.items.filter((i) => i.id !== contentRef.id);
		const page = { items };
		this.content.update({ pages: { 0: page } });
		this.onSetFiltered(this.form?.get('search')?.value ?? '');
	}

	getSelected() {
		if (!this.form) return new Map<string, DocumentReference<Content>>();
		const formValue = this.form.get('selected')?.getRawValue();
		return this.content.items.reduce((value, current) => {
			if (formValue[current.id] === true)
				value.set(current.id, current.ref);
			return value;
		}, new Map<string, DocumentReference<Content>>());
	}

	onSubmit() {
		this.form?.markAllAsTouched();
		if (this.busy || !this.form || this.form.invalid) return;

		this.busy = true;
		this.form.disable();

		const value = this.form.getRawValue();
		const selected = this.content.items.find(
			(i) => i.id === value.selected,
		);

		this.onDismiss(selected ?? null);
	}
}
