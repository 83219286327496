import { Role } from '@context/shared/types/user';
import { Observable } from 'rxjs';

const EntityTypes = ['log', 'user', 'folder', 'file'] as const;
export type EntityType = (typeof EntityTypes)[number];

export const EntityTypeMenuItems: MenuItem<EntityType>[] = [
	{
		key: 'user',
		icon: '@tui.user',
		requiredRole: 'admin',
	},
	{
		key: 'log',
		icon: '@tui.notebook-pen',
	},
	{
		key: 'file',
		// sprout for now, likely to by dynamic when we go to make the app generic
		icon: '@tui.sprout',
	},
	{
		key: 'folder',
		icon: '@tui.folder',
	},
] as const;

const MenuKeys = [
	'home',
	'create',
	'notifications',
	'archive',
	'profile',
	'sign-out',
	'users',
	'help',
] as const;
type MenuKey = (typeof MenuKeys)[number];

export type MenuItem<T = MenuKey> = {
	key: T;
	icon: string;

	location?: 'tab' | 'side' | 'all';
	requiredRole?: Role;

	route?: string;
	handler?: () => void;

	badge$?: Observable<string | null>;
};

export const MenuItems = (opts: {
	createHandler: () => void;
	notificationCount$: Observable<string | null>;
	logoutHandler: () => void;
}): MenuItem[] =>
	[
		{
			key: 'home',
			location: 'all',
			route: 'home',
			icon: '@tui.layout-dashboard',
		},
		{
			key: 'create',
			location: 'tab',
			handler: () => opts.createHandler(),
			icon: '@tui.plus',
		},
		{
			key: 'notifications',
			location: 'all',
			route: 'notifications',
			icon: '@tui.bell',
			badge$: opts.notificationCount$,
		},
		{
			key: 'archive',
			location: 'side',
			route: 'archive',
			icon: '@tui.archive',
		},
		{
			key: 'users',
			location: 'side',
			route: 'users',
			icon: '@tui.users',
			requiredRole: 'admin',
		},
		{
			key: 'profile',
			location: 'side',
			route: 'profile',
			icon: '@tui.circle-user',
		},
		{
			key: 'sign-out',
			location: 'side',
			handler: () => opts.logoutHandler(),
			icon: '@tui.log-out',
		},
	] as const;
