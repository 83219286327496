<search tuiSearch>
	<form [formGroup]="search">
		<fieldset tuiTextfieldSize="m">
			<tui-textfield iconStart="@tui.search">
				<input
					formControlName="term"
					id="app-global-search-bar"
					[placeholder]="'data.global-search-placeholder' | transloco"
					tuiTextfield
					(focus)="onFocus()"
					(blur)="onBlur()"
					[tuiDropdown]="globalSearchDropdown"
					[(tuiDropdownOpen)]="dropdownOpen"
				/>

				@if (!focused) {
					@if ('mac' | isPlatform) {
						<div class="global-search__hotkey-container">
							<ctx-hotkey key="command" />
							<ctx-hotkey key="K" />
						</div>
					} @else if ('windows' | isPlatform) {
						<div class="global-search__hotkey-container">
							<ctx-hotkey key="ctrl" />
							<ctx-hotkey key="K" />
						</div>
					}
				}
			</tui-textfield>
		</fieldset>
	</form>
</search>

<ng-template #globalSearchDropdown>
	<ctx-global-search-dropdown
		[loading]="loading"
		[data]="data"
		[totalElements]="totalElements"
		(select)="onClose()"
	/>
</ng-template>
