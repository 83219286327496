import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	inject,
	Input,
	OnInit,
} from '@angular/core';
import { StorageService } from '@context/frontend/storage';
import { ContentLog } from '@context/shared/types/log';
import { getPreferredThumbnail } from '@context/shared/types/media';
import {
	TuiFallbackSrcPipe,
	TuiIcon,
	TuiIconPipe,
	TuiSizeXL
} from '@taiga-ui/core';
import { TuiAvatar } from '@taiga-ui/kit';

@Component({
	standalone: true,
	imports: [TuiAvatar, TuiIcon, TuiIconPipe, TuiFallbackSrcPipe, AsyncPipe],
	selector: 'ctx-log-thumbnail',
	templateUrl: 'log-thumbnail.component.html',
	styleUrl: 'log-thumbnail.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogThumbnailComponent implements OnInit {
	@Input()
	log!: ContentLog;

	@Input()
	size: TuiSizeXL = 'l';

	readonly storageService = inject(StorageService);
	readonly cdRef = inject(ChangeDetectorRef);

	thumbnailUrl: string | null = null;

	ngOnInit() {
		this.fetchThumbnail().then((res) => {
			this.thumbnailUrl = res;
			this.cdRef.detectChanges();
		});
	}

	fetchThumbnail() {
		if (!this.log.thumbnails?.length) return Promise.resolve(null);

		const thumbnail = getPreferredThumbnail(this.log.thumbnails, {
			resolution: 256,
		});

		if (!thumbnail) return Promise.resolve(null);

		const storagePath = this.storageService.getReference(
			thumbnail.storagePath,
		);
		return this.storageService.getDownloadUrl(storagePath);
	}
}
