import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	HostBinding,
	inject,
	OnInit,
} from '@angular/core';
import { getDoc, where } from '@angular/fire/firestore';
import {
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import { AlertService } from '@context/frontend/alert';
import {
	DestroyService,
	DialogBase,
	DialogOptions,
	USER,
} from '@context/frontend/common';
import { getUaDevice } from '@context/frontend/native/platform';
import {
	PaginatedObject,
	PaginatedPageItem,
	PaginationPage,
} from '@context/frontend/pagination';
import { TableComponent } from '@context/frontend/table';
import {
	AuthService,
	UserRowComponent,
	UserService,
} from '@context/frontend/user';
import { Content } from '@context/shared/types/common';
import {
	getName,
	getUserStatus,
	hasInheritedRolePermission,
	User,
} from '@context/shared/types/user';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiAutoFocus } from '@taiga-ui/cdk';
import { TuiButton, TuiLoader, TuiTextfield } from '@taiga-ui/core';
import {
	TuiButtonLoading,
	TuiCheckbox,
	TuiTabs,
	TuiTabsWithMore,
} from '@taiga-ui/kit';
import { DocumentReference } from 'firebase/firestore';
import { debounceTime, takeUntil, tap } from 'rxjs';
import { ContentService } from '../../services';
import { ShareUserRowComponent } from './share-user-row/share-user-row.component';

@Component({
	standalone: true,
	imports: [
		TranslocoModule,
		TuiButton,
		TuiButtonLoading,
		TuiTextfield,
		FormsModule,
		ReactiveFormsModule,
		TuiTabs,
		TuiTabsWithMore,
		UserRowComponent,
		TuiLoader,
		TableComponent,
		TuiCheckbox,
		ShareUserRowComponent,
		TuiAutoFocus,
	],
	selector: 'ctx-share-dialog',
	templateUrl: 'share-dialog.component.html',
	styleUrl: 'share-dialog.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService],
})
export class ShareDialogComponent
	extends DialogBase<
		{
			content: Content;
			selected?: DocumentReference<User>[];
			removed?: DocumentReference<User>[];
		},
		{ content: Content }
	>
	implements OnInit
{
	static override readonly DialogOptions = {
		label: 'content.share',
		size: getUaDevice() === 'mobile' ? 'fullscreen' : 'm',
	} as DialogOptions;

	@HostBinding('attr.device')
	readonly device = getUaDevice();

	activeTabIndex = 0;
	form: FormGroup | null = null;

	searching = false;
	loading = false;
	busy = false;

	private readonly authService = inject(AuthService);
	private readonly alert = inject(AlertService);
	private readonly contentService = inject(ContentService);
	private readonly userService = inject(UserService);
	private readonly cdRef = inject(ChangeDetectorRef);
	private readonly destroy$ = inject(DestroyService);
	private readonly user = inject(USER);

	shared = new PaginatedObject<User>();
	users = new PaginatedObject<User>({
		sortBy: 'givenName',
		sortDir: 'asc',
		pageSize: 100,
	});

	filteredUsers: PaginatedPageItem<User>[] = [];
	filteredShared: PaginatedPageItem<User>[] = [];

	readonly placeholder = {
		icon: '@tui.search',
		header: 'data.search-placeholder-header',
		content: 'data.search-placeholder-content',
	};

	readonly placeholderShared = {
		icon: '@tui.share-2',
		header: 'content.shared-table-placeholder-header',
		content: 'content.shared-table-placeholder-content',
	};

	readonly placeholderUsers = {
		icon: '@tui.users',
		header: 'content.users-table-placeholder-header',
		content: 'content.users-table-placeholder-content',
	};

	ngOnInit() {
		this.setupForm();
	}

	async setupForm() {
		this.loading = true;

		await this.onLoadShared();
		await this.onLoadUsers();

		const selected = new FormGroup({});
		this.users.items.forEach((i) => {
			selected.addControl(i.id, new FormControl(false));
		});

		this.form = new FormGroup({
			search: new FormControl(),
			selected,
		});

		this.loading = false;
		this.cdRef.detectChanges();

		this.form
			.get('search')
			?.valueChanges.pipe(
				tap(() => {
					this.searching = true;
				}),
				takeUntil(this.destroy$),
				debounceTime(250),
			)
			.subscribe((term) => {
				this.searching = false;
				this.onSetFiltered(term);
			});
	}

	onUserRemoved(userRef: DocumentReference<User>) {
		const shared = this.context.data.content.shared;
		this.context.data.content.shared = [
			...shared.filter((s) => s.id !== userRef.id),
		];
		const items = this.shared.items.filter((i) => i.id !== userRef.id);
		const page = { items };
		this.shared.update({ pages: { 0: page } });
		this.onSetFiltered(this.form?.get('search')?.value ?? '');
	}

	async onLoadShared() {
		const shared = this.context.data.content.shared ?? [];
		const batch = shared.map((s) =>
			getDoc(s as DocumentReference<User>).then((s) => ({
				ref: s.ref,
				data: s.data() as User,
				id: s.id,
			})),
		);

		if (batch) {
			const items = await Promise.all(batch);
			this.shared = new PaginatedObject({
				pageSize: null,
				totalElements: items.length,
				pages: { 0: { last: ([] as any).concat(items).pop(), items } },
			});
		} else {
			this.shared.reset();
		}

		this.filteredShared = this.onFilterUsers(
			this.form?.get('search')?.value,
			this.shared.page,
		);
	}

	onLoadUsers = () => {
		const user = this.authService.user;
		if (!user || !hasInheritedRolePermission(user.role, 'super')) {
			this.users.update({ constraints: [where('role', '!=', 'super')] });
		}

		return this.userService.fetch(this.users).then((value) => {
			this.users.update(value);
			this.filteredUsers = this.onFilterUsers(
				this.form?.get('search')?.value,
				value.page,
				new Set(this.shared.items.map((i) => i.id)),
			);
		});
	};

	getSelected() {
		if (!this.form) return new Map<string, DocumentReference<User>>();
		const formValue = this.form.get('selected')?.getRawValue();
		return this.users.items.reduce((value, current) => {
			if (formValue[current.id] === true)
				value.set(current.id, current.ref);
			return value;
		}, new Map<string, DocumentReference<User>>());
	}

	onSetFiltered(term: string) {
		this.filteredShared = this.onFilterUsers(term, this.shared.page);
		this.filteredUsers = this.onFilterUsers(
			term,
			this.users.page,
			new Set(this.shared.items.map((i) => i.id)),
		);
		this.cdRef.detectChanges();
	}

	onFilterUsers = (
		term: string | null,
		page: PaginationPage<User> | null,
		exclude?: Set<string>,
	): PaginatedPageItem<User>[] => {
		const role = this.authService.user?.role ?? 'user';
		return (page?.items ?? []).reduce((value, current) => {
			term = term?.trim().toLowerCase() ?? '';
			const user = current.data;
			const status = getUserStatus(user);

			if (status !== 'enabled') return value;
			if (
				// only supers can see other super permissions
				(!hasInheritedRolePermission(role, 'super') &&
					user.role === 'super') ||
				exclude?.has(user.id) ||
				this.user.value?.id === user.id ||
				this.context.data.content.createdBy?.id === user.id
			)
				return value;

			if (
				!term ||
				getName(user).toLowerCase().includes(term) ||
				user.email.toLowerCase().includes(term) ||
				user.role.toLowerCase().includes(term) ||
				user.id.toLowerCase().includes(term)
			) {
				value.push(current);
			}

			return value;
		}, [] as PaginatedPageItem<User>[]);
	};

	onSubmit() {
		this.form?.markAllAsTouched();
		if (this.busy || !this.form || this.form.invalid) return;

		this.form.disable();
		this.busy = true;

		const selected = Array.from(this.getSelected().values());
		return this.contentService
			.shareWithUsers(this.context.data.content, selected)
			.then((content) => {
				this.onDismiss({ content: content as Content, selected });
				this.alert.open('generic-success').subscribe();
			})
			.catch((error) => {
				this.alert.open('generic-error').subscribe();
				console.error(error);
				throw new Error(
					'There was an error sharing with the selected users',
				);
			})
			.finally(() => {
				this.form?.enable();
				this.busy = false;
			});
	}
}
